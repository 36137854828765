<template>
    <div style="background:lightgrey;">
      <v-container>
        <br />
        <v-row justify="space-around">
          <v-col cols="12" sm="12">
            <center>
              <v-btn color="primary" dark class="mb-2" @click="printReceipt()" style="margin-left:10px;">
                <v-icon>mdi-content-save</v-icon>PRINT Receipt
              </v-btn>
            </center>
          </v-col>
        </v-row>
        <v-card>
            <v-card-text>
              <div id="printonlyreceipt">
                <div class="pageHeight" style="width:auto; padding:4px;">
                  <div style="position: relative;" class="printHeader">
                      <div style="text-align: center; color: blue;height: 130px;">
                        <div cols="12" sm="12" style="color:blue;">
                            <center>
                              <img style="height: 70px; width: auto;" src="https://vierp-test.s3.ap-south-1.amazonaws.com/logo/vu_logo.svg"/>
                              <div style="font-size:18px;padding-top:10px;">
                                <b>Provisional Application  (AY : {{ provisionaladmission.academicyear }})</b>
                              </div>
                            </center>
                        </div>
                      </div>
                      <div>
                        <hr style="height:1px; background:black;border-radius: 50px;"/>
                        <hr style="height:1px; background:black;border-radius: 50px;"/>
                        <br />
                      </div>
                      <div>
                        <div cols="12" sm="12">
                          <center style="font-size:18px;">
                            <b>Fees Receipt <span style="font-size:13px;">(Application Fees)</span></b>
                          </center>
                        </div>
                      </div>
                      <br />
                      <table dense style="width:100%;">
                          <tr><td style="border:1px solid whitesmoke !important;"><b>Receipt No. :</b> {{provisionaladmission.applicationFeesReceiptNumber }}</td><td style="border:1px solid whitesmoke !important;"><b>Receipt Date. :</b> {{ provisionaladmission.application_transaction_date }}</td></tr>
                          <tr><td style="border:1px solid whitesmoke !important;" colspan="2"><b>Application No. :</b> {{ provisionaladmission.applicationNumber }}</td></tr>
                          <tr><td style="border:1px solid whitesmoke !important;" colspan="2"><b>Student Name :</b> {{ provisionaladmission.firstName }} {{ provisionaladmission.lastName }}</td></tr>
                          <tr><td style="border:1px solid whitesmoke !important;" colspan="2"><b>Application Program :</b> {{ provisionaladmission.applicationprogram }}</td></tr>
                          <tr><td style="border:1px solid whitesmoke !important;"><b>Email :</b> {{provisionaladmission.received_email}}</td><td style="border:1px solid whitesmoke !important;"><b>Mobile :</b> {{provisionaladmission.received_mobileno}}</td></tr>
                      </table>
                      <table style="width: 100%;border:1px solid whitesmoke;">
                          <tr>
                            <th style="border:1px solid black !important;" align="left">
                              <center>Sr.No</center>
                            </th>
                            <th style="border:1px solid black !important;">Particulars</th>
                            <th style="border:1px solid black !important;" align="left">Amount (Rs.)</th>
                          </tr>
                          <tr>
                            <td style="border:1px solid black !important;" align="center">1</td>
                            <td style="border:1px solid black !important;" align="left">Application Fees</td>
                            <td style="border:1px solid black !important;" align="right">{{ provisionaladmission.applicationFees }}/-</td>
                          </tr>
                          <tr>
                            <td style="border:1px solid black !important;"></td>
                            <td style="border:1px solid black !important;" align="right"><b>Total Amount </b></td>
                            <td style="border:1px solid black !important;" align="right"><b>{{ provisionaladmission.applicationFees }}/-</b></td>
                          </tr>
                      </table>
                      <div>
                        <br />
                        <hr style="height:1px; background:black;border-radius: 50px;"/>
                        <br />
                      </div>
                      <br />
                      <center style="width: 80%;margin-left: 70px; word-wrap: break-word;">DETAILS OF PAYMENT MODE TOWARDS FEES DEPOSITED</center>
                      <br />
                      <table style="width: 100%">
                        <tr>
                          <th style="border:1px solid black !important;">Payment Mode</th>
                          <th style="border:1px solid black !important;">Reference No.</th>
                          <th style="border:1px solid black !important;">Pay Date</th>
                          <th style="border:1px solid black !important;">Amount (Rs.)</th>
                          <th style="border:1px solid black !important;">Drawn On Bank</th>
                        </tr>
                        <tr>
                          <td style="border:1px solid black !important;"><center>{{ provisionaladmission.erppaymentmode }}</center></td>
                          <td style="border:1px solid black !important;"><center>{{ provisionaladmission.applicationtid }}</center></td>
                          <td style="border:1px solid black !important;"><center>{{ provisionaladmission.application_transaction_date }}</center></td>
                          <td style="border:1px solid black !important;"><center>{{ provisionaladmission.applicationFees }}/-</center></td>
                          <td style="border:1px solid black !important;"><center>{{ provisionaladmission.applicationfeesbankname }}</center></td>
                        </tr>
                      </table>
                      <br />
                      <div style="width:100%;height:200px;">
                        <div style="float:right;margin-right:20px;">
                          <img style="width:150px;margin-right:50px;" :src="provisionaladmission.stamp" />
                          <center style="margin-right:40px;"><b>Seal of University</b></center>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                      <br />
                      <b style="font-size:12px;">Print Date : {{provisionaladmission.printdate}}</b>
                      <br />
                      <div cols="12" sm="12">
                        <br />
                        <span style="font-weight:bold;font-size:12px;">
                          Note : This is Computer generated Receipt, hence signature is not required.
                        </span>
                      </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
            </v-card-text>
        </v-card>
      </v-container>
    </div>
  </template>
  <script>
  import axios from "axios";
  const CryptoJS = require("crypto-js")
  import "@/assets/css/styles.css";
  export default {
    data: () => ({
      provisionadmissionid : null,
      provisionaladmission : null,
    }),
    components: {},
    mounted() {
      this.provisionadmissionid = this.$route.params.provisionadmissionid;
      if (!this.provisionadmissionid) 
          this.provisionadmissionid = this.decrypt(this.$route.query.provisionadmissionid);
  
      this.viewreceipt();
    },
    methods: {
      printReceipt() {
        var content = document.getElementById("printonlyreceipt").innerHTML;
        var mywindow = document.body.innerHTML;
        document.body.innerHTML = content;
        window.print();
        location.reload();
        document.body.innerHTML = mywindow;
      },
  
      printApplication() {
        var content = document.getElementById("printonlyapplication").innerHTML;
        var mywindow = document.body.innerHTML;
        document.body.innerHTML = content;
        window.print();
        location.reload();
        document.body.innerHTML = mywindow;
      },
  
      decrypt (src) {
        const passphrase = '987654'
        const bytes = CryptoJS.AES.decrypt(src, passphrase)
        const originalText = bytes.toString(CryptoJS.enc.Utf8)
        return originalText
      },
  
      viewreceipt(){
        const data = {
            provisionadmissionid: this.provisionadmissionid,
        };
        axios
          .post("/appLearnerAdmission/provisionalapplicationreceipt", data)
          .then((res) => {
            if (res.data.status == "SUCCESS") {
              this.provisionaladmission = res.data.provisionaladmission;
            } else {
              console.log("ERROR in API call");
            }
          })
          .catch((error) => {
            window.console.log(error);
            this.message = "Something went wrong";
          });
      },
    },
  };
  </script>
  <style scoped>
  .text-left {
    padding-left: 10px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin: 0px !important;
  }
  .text-left-head {
    font-size: 14px;
    font-weight: bold;
  }
  
  input,
  textarea {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
  h6 {
    font-size: 1em;
    font-family: "Times New Roman", Times, serif ";";
  }
  input[type="text"] {
    padding: 4px;
  
    border: none;
  }
  table {
    border-collapse: collapse;
  
    font-size: 12px;
    width: 80%;
  }
  
  table,
  th,
  td {
    border: 1px solid black;
  }
  th {
    height: 30px !important;
    text-align: center !important;
  }
  th,
  td {
    padding:2px;
  }
  .font-text {
    font-family: "Times New Roman";
  }
  #printonlyreceipt {
    display: block;
  }
  #printonlyapplication{
      display: block;
  }
  #noprint {
    display: block;
  }
  .pageHeight {
    height: auto;
  }
  
  .photo{
      border: 1px solid black !important;
      height: 130px;
      width: 120px;
      margin: 10px;
      padding: 20px;
      text-align: center;
  }
  @media print {
    #printonlyreceipt {
      display: block;
      font-size: 10px;
    }
    #printonlyapplication{
      display: block;
      font-size: 10px;
    }
    @page {
      size: letter;
    }
    #noprint {
      display: none;
    }
    .pageHeight {
      height: 230mm;
    }
  
    @page {
      margin-left: 2.1cm;
      margin-right: 1cm;
      margin-top: 0.5cm;
      margin-bottom: 0.5cm;
      counter-increment: page;
      content: counter(page);
    }
    body {
      font-size: 15px !important;
    }
  
    a[href] {
      display: none !important;
    }
    button {
      display: none !important;
    }
    input[type="submit"],
    input[type="button"] {
      display: none !important;
    }
    hr {
      border: none !important;
    }
    input[type="text"] {
      border: none !important;
    }
    input,
    textarea {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
  </style>
  